import styled from "styled-components";

export const HomeSliderContainer = styled.div`
    width: 100%;
    height: 55%;
    background: url(${({ image }) => image});
    background-repeat: no-repeat;
    background-size: cover;

    .slick-slider.slick-initialized {
        height: 100%;
        * {
            height: 100%;
        }
    }

    .slick-dots {
        height: auto !important;
        bottom: 25px;

        li {
            margin: auto;
        }

        li button:before {
            color: white;
            font-size: 10px;
            opacity: 1;
        }
        
        li.slick-active button:before {
            color: #1D3058;
        }
    }
`