import React from "react"
import { PDFSliderBackground, PDFSliderContainer } from "./PDFSlider.style"
import Slider from "react-slick"
import { CDN } from "../../data"

const PDFSlider = ({ data = [] }) => {
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        touchThreshold: 20,
    }

    const items = []
    if (data) {
        for (let i = 0; i < data.images; i++) {
            items.push(
                <div key={"item " + i}>
                    <div style={{ margin: "0px 30px" }}>
                        <img
                            style={{
                                margin: "auto",
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                            }}
                            src={`${CDN}${data.path}/${i + 1}.jpg`}
                            alt={"item " + i}
                        />
                    </div>
                </div>
            )
        }
    }

    return (
        <div>
            <PDFSliderBackground />
            <PDFSliderContainer>
                <Slider {...settings}>{items}</Slider>
            </PDFSliderContainer>
        </div>
    )
}

export default PDFSlider
