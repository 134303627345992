import { useContext, useEffect } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { CDN } from "../../data"
import { DataContext } from "../../pages/Main"
import { addPageView } from "../../parse/addparse"
import { SideBarContainer, SideBarItemContainer, SideBarText } from "./SideBar.style"

const SideBar = ({ open, onClose }) => {
    const data = useContext(DataContext)
    const history = useHistory()
    const location = useLocation()
    const { lang } = useParams()

    useEffect(() => {
        onClose()
    }, [location, onClose])

    const handleLang = () => {
        history.push("/" + (lang === "es" ? "en" : "es"))
        onClose()
    }

    const handleHome = () => {
        history.push("/" + lang)
        onClose()
    }

    const handleContact = () => {
        history.push("/" + lang + "/contact")
        onClose()
    }

    const handleWhatsapp = () => {
        addPageView("/whatsapp/")
        const aElement = document.createElement("a")
        aElement.href = "https://wa.me/" + data.number
        aElement.click()
    }

    return (
        <SideBarContainer open={open}>
            <div id="exitButton" onClick={onClose}>
                <img alt="Exit button" src={CDN + "/images/exitburger.png"} />
            </div>
            <div id="bodyContainer">
                <SideBarItemContainer onClick={handleLang} id="langDiv" className="bcontainer">
                    <SideBarText className="textSidebar">EN</SideBarText>
                    <div id="separator" />
                    <SideBarText className="textSidebar">ES</SideBarText>
                </SideBarItemContainer>

                <SideBarItemContainer onClick={handleHome} className="bline">
                    <SideBarText>{data && data.text.home}</SideBarText>
                </SideBarItemContainer>

                <SideBarItemContainer onClick={handleContact} className="bline">
                    <SideBarText>{data && data.text.contact}</SideBarText>
                </SideBarItemContainer>

                <SideBarItemContainer onClick={handleWhatsapp}>
                    <SideBarText>Whatsapp</SideBarText>
                </SideBarItemContainer>
            </div>
        </SideBarContainer>
    )
}

export default SideBar
