import { useContext } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"
import Button from "../components/ui/Button"
import { CDN } from "../data"
import { AllProductsContainer, ProductItemContainer } from "./AllProducts.style"
import { DataContext } from "./Main"

const ProductItem = ({ title, src, alt, ...props }) => {
    return (
        <ProductItemContainer {...props}>
            <img src={src} alt={alt} />
            <div id="title">{title}</div>
        </ProductItemContainer>
    )
}

const AllProducts = () => {
    const data = useContext(DataContext)
    const products = data.products

    const { lang } = useParams()
    const history = useHistory()
    const location = useLocation()

    const items = []

    for (let i = 0; i < products.length; i++) {
        const product = products[i]
        items.push(
            <ProductItem
                key={"product" + i}
                title={product.title}
                src={`${CDN}${product.path}/preview.png`}
                onClick={() => {
                    history.push(location.pathname + "/" + i)
                }}
            />
        )
    }

    return (
        <>
            <Button
                onClick={() => history.push("/" + lang)}
                invert
                style={{ margin: "0 0 20px 0" }}
            >
                {data.text.back}
            </Button>
            <AllProductsContainer>{items}</AllProductsContainer>
        </>
    )
}

export default AllProducts
