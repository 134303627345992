import styled from "styled-components"

export const PDFSliderContainer = styled.div`
    position: fixed;
    top: 140px;
    left: 0;
    width: 100%;
    height: calc(100% - 224px);
    .slick-slider.slick-initialized {
        height: 100%;
        * {
            height: 100%;
        }
    }

    .slick-arrow.slick-next {
        height: auto;
        right: 5px;
        width: 20px;
        height: 48px;
        background-color: rgba(0, 0, 0, 0.8);
        border-radius: 5px;

        ::before {
            content: "";
            border: solid white;
            border-width: 0 3px 3px 0;
            display: inline-block;
            padding: 3px;
            margin-right: 3px;
            transform: rotate(-45deg);
        }
    }

    .slick-arrow.slick-prev {
        z-index: 1;
        height: auto;
        left: 5px;
        width: 20px;
        height: 48px;
        background-color: rgba(0, 0, 0, 0.8);
        border-radius: 5px;

        ::before {
            content: "";
            border: solid white;
            border-width: 0 3px 3px 0;
            display: inline-block;
            padding: 3px;
            margin-left: 3px;
            transform: rotate(135deg);
        }
    }
`

export const PDFSliderBackground = styled.div`
    background-color: #383838;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
`
