import { CDN } from "../../data"
import { VPlayerContainer } from "./VideoPlayer.style"

const VideoPlayer = ({ preview, alt, ...props }) => {
    return (
        <VPlayerContainer {...props}>
            <img id="preview" src={preview} alt={alt} />
            <img id="playButton" src={CDN + "/images/playbutton.png"} alt={"Play"} />
        </VPlayerContainer>
    )
}

export default VideoPlayer
