import styled from "styled-components";

export const HBodyContainer = styled.div`
    width: 100%;
    height: 45%;

    #imageContainer { 
        position: relative;
        width: 100%;
        height: 50%;
        display:flex;
        justify-content: center;
        align-items: center;
    
        img {
            height: 95px;
        }
    }

    #bodyContainer {
        position: relative;
        width: 100%;
        margin-top: 20px;
        height: calc(50% - 20px);
        
        #title {
            color: white;
            text-align: center;
        }

        #dobleButton {
            display: flex;
            margin: 30px 20px 0px 20px;
            justify-content: center;
            align-items: center;

            .langButton {
                margin: 0px 20px;
            }
        }
    }
`