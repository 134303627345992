import Slider from "react-slick"
import { CDN } from "../../data"
import { HomeSliderContainer } from "./HomeSlider.style"

const HomeSlider = () => {
    var settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        touchThreshold: 20,
    }

    return (
        <HomeSliderContainer>
            <Slider {...settings}>
                <div>
                    <div>
                        <img
                            style={{
                                margin: "auto",
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                            }}
                            src={CDN + "/images/homeslider/1.jpg"}
                            alt="slider1"
                        />
                    </div>
                </div>
                <div>
                    <div>
                        <img
                            style={{
                                margin: "auto",
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                            }}
                            src={CDN + "/images/homeslider/2.jpg"}
                            alt="slider2"
                        />
                    </div>
                </div>
                <div>
                    <div>
                        <img
                            style={{
                                margin: "auto",
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                            }}
                            src={CDN + "/images/homeslider/3.jpg"}
                            alt="slider3"
                        />
                    </div>
                </div>
            </Slider>
        </HomeSliderContainer>
    )
}

export default HomeSlider
