import english from "./english"
import spanish from "./spanish"

const dataPage = {
    es: spanish,
    en: english,
}

export const CDN = ""

export default dataPage