import { useContext, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { CDN } from "../../data"
import { DataContext } from "../../pages/Main"
import { addPageView } from "../../parse/addparse"
import Button from "./Button"
import PDFSlider from "./PDFSlider"
import { PDFBarContainer, PDFBarItem, Separator } from "./PDFViewer.style"
import Qualification from "./Qualification"

let interval = undefined

const PDFViewer = ({ data }) => {
    const { mail, number, text } = useContext(DataContext)

    const location = useLocation()
    const history = useHistory()
    const [openQualification, setOpenQualification] = useState(false)

    const handleEnableQualification = () => {
        if (interval) {
            clearInterval(interval)
        }

        interval = setTimeout(() => setOpenQualification(true), 1000)
    }

    const handleDownload = () => {
        addPageView("/download/" + data.title)
        const aElement = document.createElement("a")
        aElement.href = CDN + data.path + "/" + data.pdf
        aElement.setAttribute("download", "")
        aElement.click()

        handleEnableQualification()
    }

    const handleMail = () => {
        addPageView("/mail/" + data.title)
        const aElement = document.createElement("a")
        aElement.href =
            "mailto:" + mail + "?subject=" + data.title + "&body=" + data.subject + " " + data.title

        aElement.click()

        handleEnableQualification()
    }

    const handleWhatsapp = () => {
        addPageView("/whatsapp/" + data.title)
        const aElement = document.createElement("a")
        aElement.setAttribute("target", "_blank")
        aElement.href = "https://wa.me/" + number + "?text=" + data.subject + " " + data.title
        aElement.click()

        handleEnableQualification()
    }

    return (
        <div>
            <PDFSlider data={data} />
            <Button
                style={{ position: "fixed" }}
                onClick={() => {
                    const path = location.pathname.split("/")
                    path.pop()
                    history.replace(path.join("/"))
                }}
            >
                {text.back}
            </Button>
            <PDFBarContainer>
                <PDFBarItem onClick={handleWhatsapp}>
                    <img src={CDN + "/images/whatsappicon.png"} alt="Whatsapp Icon" />
                </PDFBarItem>
                <Separator />
                <PDFBarItem onClick={handleMail}>
                    <img src={CDN + "/images/lettericon.png"} alt="Whatsapp Icon" />
                </PDFBarItem>
                <Separator />
                <PDFBarItem onClick={handleDownload}>
                    <img src={CDN + "/images/downloadicon.png"} alt="Whatsapp Icon" />
                </PDFBarItem>
            </PDFBarContainer>

            <Qualification open={openQualification} onClose={() => setOpenQualification(false)} />
        </div>
    )
}

export default PDFViewer
