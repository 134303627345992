import styled from "styled-components"

export const SideBarContainer = styled.div`
    position: fixed;

    top: 0;
    left: 0;
    transition: transform 400ms;
    transform: translateX(${({ open }) => (open ? "0px" : "-100%")});
    width: 150px;
    height: 100%;
    background: rgba(0, 0, 0, 0.94);
    z-index: 2;

    #exitButton {
        position: relative;
        width: 60px;
        height: 70px;
        cursor: pointer;

        img {
            position: absolute;
            transform: translate(-50%, -50%);
            width: 15px;
            height: 15px;
            top: 50%;
            left: 50%;
        }
    }

    #bodyContainer {
        margin: 10px 20px 20px 20px;

        #langDiv {
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 0;
            display: flex;
            justify-content: center;

            #separator {
                width: 1px;
                height: 35px;
                margin: 0px 20px;
                background-color: white;
            }
        }
    }

    .bline {
        border-bottom: 1px solid white;
    }
`

export const SideBarText = styled.div`
    font-size: 16px;
    color: white;
`

export const SideBarItemContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    cursor: pointer;
`
