import { useContext, useState } from "react"
import styled from "styled-components"
import { DataContext } from "../../pages/Main"
import {
    getShowedQualification,
    sendQualification,
    setShowedQualification,
} from "../../parse/addparse"

const QualificationContainer = styled.div`
    position: absolute;
    width: 300px;
    height: 141px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(4, 64, 125, 0.8);
    padding: 20px;
    border-radius: 10px;
    transition: opacity 400ms;

    h4 {
        color: white;
        margin: 0;
        text-align: center;
    }

    p {
        color: white;
        font-size: 14px;
        text-align: center;
        margin: 5px 0px;
    }

    .starContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15px;

        .star {
            fill: none;
            stroke: white;
            stroke-width: 2px;
            margin: 0 5px;
        }

        .star.filled {
            fill: white;
        }
    }
`

const Container = styled.div`
    display: ${({ open }) => (open ? "block" : "none")};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: ${({ sending }) => (sending ? "none" : "all")};

    #background {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
    }

    ${QualificationContainer} {
        opacity: ${({ sending }) => (sending ? "0.7" : "1")};
    }
`

const Star = ({ className, onClick }) => (
    <svg
        className={className}
        onClick={onClick}
        xmlns="http://www.w3.org/2000/svg"
        width="30px"
        height="30px"
        viewBox="-3 -3 29 29"
    >
        <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" />
    </svg>
)

const Qualification = ({ open, onClose }) => {
    const { text } = useContext(DataContext)
    const [countStar, setCountStart] = useState(0)
    const [sending, setSending] = useState(false)

    open = open && !getShowedQualification()

    const handleSetCount = (value) => {
        setCountStart(value)
        setSending(true)

        sendQualification(value).then(() => {
            setShowedQualification()
            onClose()
        })
    }

    return (
        <Container open={open} sending={sending}>
            <div id="background" />
            <QualificationContainer>
                <h4>{text.calif_title}</h4>
                <p>{text.calif_description}</p>
                <div className="starContainer">
                    <Star
                        className={`star ${countStar >= 1 && "filled"}`}
                        onClick={() => handleSetCount(1)}
                    />
                    <Star
                        className={`star ${countStar >= 2 && "filled"}`}
                        onClick={() => handleSetCount(2)}
                    />
                    <Star
                        className={`star ${countStar >= 3 && "filled"}`}
                        onClick={() => handleSetCount(3)}
                    />
                    <Star
                        className={`star ${countStar >= 4 && "filled"}`}
                        onClick={() => handleSetCount(4)}
                    />
                    <Star
                        className={`star ${countStar >= 5 && "filled"}`}
                        onClick={() => handleSetCount(5)}
                    />
                </div>
            </QualificationContainer>
        </Container>
    )
}

export default Qualification
