import styled from "styled-components"

export const HomeContainer = styled.div`
    position: absolute;
    width: 100vw;
    height: 100%;
    background-color: #1d3058;
    display: flex;
    flex-direction: column;
`
