import styled from "styled-components"

export const AllProductsContainer = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
`

export const ProductItemContainer = styled.div`
    position: relative;
    width: calc(50vw - 40px);
    height: calc(50vw - 40px);
    margin-bottom: 45px;
    background: #262626;
    box-shadow: 0 4px 2px 0px #00000055;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;

    #title {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50px;
        background: rgba(0, 0, 0, 0.8);
        color: #8b8b8b;
        font-size: 18px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img {
        width: 100%;
        height: 100%;
    }
`
