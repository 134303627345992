import styled from "styled-components"

const Container = styled.button`
    position: relative;
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    background: ${({ background }) => background};
    border-radius: 40px;
    border: 1px solid ${({ borderColor }) => borderColor};
    color: ${({ color }) => color};
    font-weight: bold;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

const Button = ({
    height = "37px",
    width = "110px",
    background = "#1D3058",
    color = "white",
    borderColor = "white",
    invert,
    children,
    ...props
}) => {

    if(invert) {
        background = "white";
        color = "#1D3058"
        borderColor = color
    }

    return (
        <Container {...props} borderColor={borderColor} height={height} width={width} background={background} color={color}>
            {children}
        </Container>
    )
}

export default Button
