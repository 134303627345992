import React from "react"
import { Route, Switch, useParams } from "react-router-dom"
import styled from "styled-components"
import TopBar from "../components/ui/TopBar"
import dataPage from "../data"
import AllProducts from "./AllProducts"
import Contact from "./Contact"
import Principal from "./Principal"
import Product from "./Product"
import Service from "./Service"
import VideoPage from "./VideoPage"

export const DataContext = React.createContext({})

const Container = styled.div`
    #body {
        margin-top: 60px;
        padding: 30px;
    }
`

const Main = () => {
    const { lang } = useParams()

    return (
        <DataContext.Provider value={dataPage[lang]}>
            <Container>
                <div id="header">
                    <TopBar />
                </div>
                <div id="body">
                    <Switch>
                        <Route exact path="/:lang/" children={<Principal />} />
                        <Route exact path="/:lang/services" children={<Service />} />
                        <Route exact path="/:lang/products" children={<AllProducts />} />
                        <Route exact path="/:lang/products/:product" children={<Product />} />
                        <Route exact path="/:lang/video" children={<VideoPage />} />
                        <Route exact path="/:lang/contact" children={<Contact />} />
                    </Switch>
                </div>
            </Container>
        </DataContext.Provider>
    )
}

export default Main
