import styled from "styled-components"

export const PDFBarContainer = styled.div`
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 70px;
    background: #1d3058;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const PDFBarItem = styled.div`
    position: relative;
    width: 33.33%;
    height: 100%;
    cursor: pointer;

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 45px;
        height: 45px;
        transform: translate(-50%, -50%);
    }
`

export const Separator = styled.div`
    width: 2px;
    height: 40px;
    background-color: white;
`

export const CircleExit = styled.div`
    position: fixed;
    top: 120px;
    right: 15px;
    width: 48px;
    height: 48px;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 50%;

    ::before {
        content: "";
        position: absolute;
        display: inline-block;
        background: urL(${({ src }) => src});
        width: 20px;
        height: 20px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`
