import styled from "styled-components"

export const MCardContainer = styled.div`
    width: 100%;
    height: 45vw;
    display: flex;
    margin-bottom: 40px;
    text-decoration: none;

    img {
        width: 60%;
        object-fit: contain;
        object-position: left;
    }

    #contdiv {
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .mcardtitle {
        margin-bottom: 20px;
        font-size: 20px;
        color: #1d3058;
        font-weight: bold;
    }

    @media only screen and (max-width: 350px) {
        .mcardtitle {
            font-size: 18px;
        }
    }
`
