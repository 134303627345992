import { useHistory } from "react-router-dom"
import { CDN } from "../../data"
import Button from "../ui/Button"
import { HBodyContainer } from "./HomeBody.style"

const HomeBody = () => {
    const history = useHistory()
    return (
        <HBodyContainer>
            <div id="imageContainer">
                <img alt="Logo" src={CDN + "/images/LOGO.png"} />
            </div>
            <div id="bodyContainer">
                <div id="title">Select your language</div>
                <div id="dobleButton">
                    <Button className="langButton" onClick={() => history.push("/en")}>
                        ENGLISH
                    </Button>
                    <Button
                        className="langButton"
                        color="#1D3058"
                        background="white"
                        onClick={() => history.push("/es")}
                    >
                        SPANISH
                    </Button>
                </div>
            </div>
        </HBodyContainer>
    )
}

export default HomeBody
