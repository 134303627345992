import { useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import styled from "styled-components"
import { CircleExit } from "../components/ui/PDFViewer.style"
import VideoJS from "../components/ui/Video.Js"
import { CDN } from "../data"
import { addPageView } from "../parse/addparse"

const VideoContainer = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: #383838;

    .video-js {
        width: 100%;
        height: 100%;
    }

    ${CircleExit} {
        top: 20px;
        right: 20px;
    }
`
// const ExitButton =
const VideoPage = () => {
    const location = useLocation()
    const history = useHistory()

    const videoJsOptions = {
        // lookup the options in the docs for more options
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: false,
        sources: [
            {
                src: CDN + "/videos/presentation.mp4",
                type: "video/mp4",
            },
        ],
    }

    useEffect(() => {
        addPageView(window.location.pathname)
    }, [])

    return (
        <VideoContainer>
            <VideoJS options={videoJsOptions} />
            <CircleExit
                src={CDN + "/images/exiticon.png"}
                onClick={() => {
                    const path = location.pathname.split("/")
                    path.pop()
                    history.replace(path.join("/"))
                }}
            />
        </VideoContainer>
    )
}

export default VideoPage
