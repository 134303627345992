import styled from "styled-components"

export const TBarContainer = styled.div`
    position: fixed;
    width: 100vw;
    height: 70px;
    top: 0;
    left: 0;
    background: #1d3058;
    z-index: 2;

    .toplogo {
        position: absolute;
        height: 40px;
        right: 20px;
        top: 15px;
    }

    .burger {
        position: absolute;
        width: 80px;
        height: 100%;
        top: 0;
        left: 0;
        cursor: pointer;

        .burgerButton {
            position: absolute;
            width: 30px;
            height: 30px;
            left: 20px;
            top: 20px;
        }
    }
`
