const spanish = {
    mail: "tumiqr@gmail.com",
    number: "+51966424278",
    text: {
        home: "Inicio",
        services: "Servicios",
        products: "Productos",
        contact: "Contacto",
        form_title: "Escríbenos para más información",
        form_subtitle: "Para comunicarte con nosotros por favor complete el siguiente formulario.",
        name_placeholder: "Nombres y Apellidos",
        mail_placeholder: "Correo Electronico",
        phone_placeholder: "Telefono",
        company_placeholder: "Empresa",
        country_placeholder: "País",
        message_placeholder: "Mensaje",
        obligatory_placeholder: "* Campos obligatorios",
        send: "Enviar",
        back: "Volver",
        send_successfully: "Mensaje enviado exitosamente",
        sending: "Enviando mensaje...",
        send_error: "Error al enviar el mensaje",
        calif_title: "Califica tu experiencia",
        calif_description: "¡Nos encantaría un puntaje de 5 estrellas!",
        enter: "Ingresar",
    },
    services: {
        title: "Servicios",
        path: "/pdf/es/services",
        images: 12,
        pdf: "Brochure servicios.pdf",
        subject: "Hola,%0D%0AEstoy interesado en recibir mas informacion de los",
    },
    products: [
        {
            title: "Productos",
            path: "/pdf/es/products",
            images: 18,
            pdf: "Brochure productos.pdf",
            subject: "Hola,%0D%0AEstoy interesado en recibir mas informacion de los",
        },
        {
            title: "SBM 21 BX",
            path: "/pdf/es/SBM 21 BX 1",
            images: 2,
            pdf: "SBM 21 BX 1.pdf",
            subject: "Hola,%0D%0AEstoy interesado en recibir mas informacion del modelo",
        },
        {
            title: "SBM 300",
            path: "/pdf/es/SBM 300",
            images: 2,
            pdf: "SBM 300.pdf",
            subject: "Hola,%0D%0AEstoy interesado en recibir mas informacion del modelo",
        },
        {
            title: "SBM 400LP",
            path: "/pdf/es/SBM 400LP",
            images: 2,
            pdf: "SBM 400LP.pdf",
            subject: "Hola,%0D%0AEstoy interesado en recibir mas informacion del modelo",
        },
        {
            title: "SBM 400SP",
            path: "/pdf/es/SBM 400SP",
            images: 2,
            pdf: "SBM 400SP.pdf",
            subject: "Hola,%0D%0AEstoy interesado en recibir mas informacion del modelo",
        },
        {
            title: "SBM 400SR",
            path: "/pdf/es/SBM 400SR",
            images: 2,
            pdf: "SBM 400SR.pdf",
            subject: "Hola,%0D%0AEstoy interesado en recibir mas informacion del modelo",
        },
        {
            title: "SBM 700",
            path: "/pdf/es/SBM 700",
            images: 2,
            pdf: "SBM 700.pdf",
            subject: "Hola,%0D%0AEstoy interesado en recibir mas informacion del modelo",
        },
        {
            title: "SBM 700SR",
            path: "/pdf/es/SBM 700SR",
            images: 2,
            pdf: "SBM 700SR.pdf",
            subject: "Hola,%0D%0AEstoy interesado en recibir mas informacion del modelo",
        },
        {
            title: "SBM 800",
            path: "/pdf/es/SBM 800",
            images: 2,
            pdf: "SBM 800.pdf",
            subject: "Hola,%0D%0AEstoy interesado en recibir mas informacion del modelo",
        },
        {
            title: "SBM 800XP",
            path: "/pdf/es/SBM 800XP",
            images: 2,
            pdf: "SBM 800XP.pdf",
            subject: "Hola,%0D%0AEstoy interesado en recibir mas informacion del modelo",
        },
    ],
}

export default spanish
