import { useContext } from "react"
import { useHistory, useLocation } from "react-router-dom"
import MainCard from "../components/ui/MainCard"
import VideoPlayer from "../components/ui/VideoPlayer"
import { DataContext } from "./Main"
import { PrincipalContainer } from "./Principal.style"
import { CDN } from "../data"

const Principal = () => {
    const { pathname } = useLocation()
    const history = useHistory()

    const data = useContext(DataContext)

    return (
        <PrincipalContainer>
            <MainCard
                src={CDN + "/images/services.png"}
                alt="Services - Servicios"
                text={data.text.services}
                to={pathname + "/services"}
            />
            <MainCard
                src={CDN + "/images/products.png"}
                alt="Products - Productos"
                text={data.text.products}
                to={pathname + "/products"}
            />

            <VideoPlayer
                onClick={() => history.push(pathname + "/video")}
                to={pathname + "/video"}
                alt="Preview Video"
                preview={CDN + "/images/previewvideo.png"}
            />
        </PrincipalContainer>
    )
}

export default Principal
