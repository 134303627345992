import styled from "styled-components";

const Textarea = styled.textarea`
    width: 100%;
    height: 150px;
    box-shadow: 0 0 5px 0 #00000011;
    border: 1px solid #ecf1f6;
    border-radius: 5px;
    padding: 10px 10px;
    resize: none;

    ::placeholder {
        color: #94A3B8;
    }
`

export default Textarea