import React, { useState } from "react"
import styled from "styled-components"
import videojs from "video.js"
import "video.js/dist/video-js.css"
import { CDN } from "../../data"

const ControlButtonContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    transition: opacity 400ms;

    #playpause {
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        #clickable,
        #pause,
        #play {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        #play {
            display: ${({ isPlay }) => (isPlay ? "none" : "block")};
        }

        #pause {
            display: ${({ isPlay }) => (!isPlay ? "none" : "block")};
        }

        #clickable {
            width: 100px;
            height: 100px;
        }
    }
`

const VideoTheme = styled.div`
    width: 100%;
    height: 100%;

    .vjs-matrix {
        .vjs-control-bar {
            height: 6em;
            background-color: rgba(0, 0, 0, 0);
            align-items: center;
        }

        .vjs-big-play-button,
        .vjs-remaining-time,
        .vjs-picture-in-picture-control,
        .vjs-play-control,
        .vjs-volume-panel {
            display: none;
        }

        .vjs-progress-control {
            height: 10px;
            .vjs-progress-holder {
                font-size: 1.666em;
                background-color: rgb(255 255 255);
            }
        }

        .vjs-modal-dialog .vjs-modal-dialog-content,
        .video-js .vjs-modal-dialog,
        .vjs-button > .vjs-icon-placeholder:before,
        .video-js .vjs-big-play-button .vjs-icon-placeholder:before {
            top: 25%;
        }

        .vjs-load-progress div {
            background: rgb(113 220 195);
        }

        .vjs-play-progress,
        .vjs-slider-bar {
            background-color: #23ce6b;
            color: #23ce6b;
        }
    }

    .vjs-has-started.vjs-user-inactive.vjs-playing ${ControlButtonContainer} {
        opacity: 0;
        transition: opacity 1s;
    }
`

export const VideoJS = (props) => {
    const videoRef = React.useRef(null)
    const [player, setPlayer] = useState()
    const { options } = props
    const [play, setPlay] = useState(false)

    const handlePlay = () => {
        setPlay(true)
    }

    const handlePause = () => {
        setPlay(false)
    }

    const handleChangePlay = () => {
        if (player) {
            if (play) {
                player.pause()
            } else {
                player.play()
            }
        }
    }

    // This seperate functional component fixes the removal of the videoelement
    // from the DOM when calling the dispose() method on a player
    React.useEffect(() => {
        const videoElement = videoRef.current
        let player
        if (videoElement) {
            player = videojs(videoElement, options, () => {
                console.log("player is ready")
            })

            player.addClass("vjs-matrix")

            player.on("pause", handlePause)
            player.on("play", handlePlay)

            setPlayer(player)
        }
        return () => {
            if (player) {
                player.dispose()
            }
        }
    }, [options])

    return (
        <VideoTheme>
            <div data-vjs-player>
                <video ref={videoRef} className="video-js vjs-big-play-centered" />
                <ControlButtonContainer isPlay={play}>
                    <div id="playpause">
                        <img id="play" src={CDN + "/images/playvideo.png"} alt="play video" />
                        <img id="pause" src={CDN + "/images/pausevideo.png"} alt="pause video" />
                        <div id="clickable" onClick={handleChangePlay} />
                    </div>
                </ControlButtonContainer>
            </div>
        </VideoTheme>
    )
}

export default VideoJS