import { useCallback, useState } from "react"
import { CDN } from "../../data"
import SideBar from "./SideBar"
import { TBarContainer } from "./TopBar.style"

const TopBar = () => {
    const [open, setOpen] = useState(false)

    const handleOnClose = useCallback(() => {
        setOpen(false)
    }, [])

    return (
        <>
            <TBarContainer>
                <img className="toplogo" alt="Logo - topbar" src={CDN + "/images/LOGO.png"} />
                <div className="burger" onClick={() => setOpen(!open)}>
                    <img
                        className="burgerButton"
                        alt="top Burger"
                        src={CDN + "/images/burger.png"}
                    />
                </div>
            </TBarContainer>
            <SideBar open={open} onClose={handleOnClose} />
        </>
    )
}

export default TopBar
