import { useContext, useEffect } from "react"
import { useParams } from "react-router-dom"
import PDFViewer from "../components/ui/PDFViewer"
import { addPageView } from "../parse/addparse"
import { DataContext } from "./Main"

const Product = () => {
    const { product } = useParams()

    const data = useContext(DataContext)
    const currentProduct = data.products[product]

    useEffect(() => {
        if (currentProduct) {
            addPageView(window.location.pathname, currentProduct.title)
        }
    }, [currentProduct])

    return <PDFViewer data={currentProduct} />
}

export default Product
