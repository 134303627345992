const english = {
    mail: "tumiqr@gmail.com",
    number: "+51966424278",
    text: {
        home: "Home",
        services: "Services",
        products: "Products",
        contact: "Contact",
        form_title: "Write us for more information",
        form_subtitle: "To communicate with us please complete the following form.",
        name_placeholder: "Names y Surnames",
        mail_placeholder: "E-mail",
        phone_placeholder: "Phone",
        company_placeholder: "Company",
        country_placeholder: "Country",
        message_placeholder: "Message",
        obligatory_placeholder: "* Required fields",
        send: "Send",
        back: "Back",
        send_successfully: "Message sent successfully",
        sending: "Sending message...",
        send_error: "Error sending message",
        calif_title: "Rate your experience",
        calif_description: "We would love 5 stars of score!",
        enter: "Enter",
    },
    services: {
        title: "Services",
        path: "/pdf/en/services",
        images: 12,
        pdf: "Brochure services.pdf",
        subject: "Hello,%0D%0AI’m interested in know more information about the",
    },
    products: [
        {
            title: "Products",
            path: "/pdf/en/products",
            images: 18,
            pdf: "Brochure products.pdf",
            subject: "Hello,%0D%0AI’m interested in know more information about the",
        },
        {
            title: "SBM 21 BX",
            path: "/pdf/en/SBM 21 BX 1",
            images: 2,
            pdf: "SBM 21 BX 1.pdf",
            subject: "Hello,%0D%0AI’m interested in know more information about the model",
        },
        {
            title: "SBM 300",
            path: "/pdf/en/SBM 300",
            images: 2,
            pdf: "SBM 300.pdf",
            subject: "Hello,%0D%0AI’m interested in know more information about the model",
        },
        {
            title: "SBM 400LP",
            path: "/pdf/en/SBM 400LP",
            images: 2,
            pdf: "SBM 400LP.pdf",
            subject: "Hello,%0D%0AI’m interested in know more information about the model",
        },
        {
            title: "SBM 400SP",
            path: "/pdf/en/SBM 400SP",
            images: 2,
            pdf: "SBM 400SP.pdf",
            subject: "Hello,%0D%0AI’m interested in know more information about the model",
        },
        {
            title: "SBM 400SR",
            path: "/pdf/en/SBM 400SR",
            images: 2,
            pdf: "SBM 400SR.pdf",
            subject: "Hello,%0D%0AI’m interested in know more information about the model",
        },
        {
            title: "SBM 700",
            path: "/pdf/en/SBM 700",
            images: 2,
            pdf: "SBM 700.pdf",
            subject: "Hello,%0D%0AI’m interested in know more information about the model",
        },
        {
            title: "SBM 700SR",
            path: "/pdf/en/SBM 700SR",
            images: 2,
            pdf: "SBM 700SR.pdf",
            subject: "Hello,%0D%0AI’m interested in know more information about the model",
        },
        {
            title: "SBM 800",
            path: "/pdf/en/SBM 800",
            images: 2,
            pdf: "SBM 800.pdf",
            subject: "Hello,%0D%0AI’m interested in know more information about the model",
        },
        {
            title: "SBM 800XP",
            path: "/pdf/en/SBM 800XP",
            images: 2,
            pdf: "SBM 800XP.pdf",
            subject: "Hello,%0D%0AI’m interested in know more information about the model",
        },
    ],
}

export default english
