import { useContext, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import styled from "styled-components"
import Button from "../components/ui/Button"
import Input from "../components/ui/Input"
import Qualification from "../components/ui/Qualification"
import Textarea from "../components/ui/Textarea"
import { getShowedQualification, sendMessageContact } from "../parse/addparse"
import { DataContext } from "./Main"

const Container = styled.div`
    h3 {
        text-align: center;
        color: #1d3058;
        margin: 0 0 10px 0;
    }
    p {
        font-size: 10px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 40px;
    }

    ${Input} {
        margin-bottom: 24px;
    }

    #obliTags {
        font-size: 10px;
        color: #c2cad7;
    }

    #button {
        margin: 30px auto 0 auto;
    }
`

const MessageInfoContainer = styled.div`
    display: ${({ open }) => (open ? "block" : "none")};
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    #background {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
    }

    #message {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 250px;
        height: 70px;
        border-radius: 10px;
        background-color: rgba(6, 64, 125, 0.85);
        display: flex;
        justify-content: center;
        align-items: center;

        h5 {
            color: white;
            font-weight: bold;
            text-align: center;
        }
    }
`

const Contact = () => {
    const data = useContext(DataContext)
    const { lang } = useParams()
    const history = useHistory()
    const [openInfo, setOpenInfo] = useState(false)
    const [textSend, setTextSend] = useState(undefined)
    const [openQua, setOpenQua] = useState(false)

    const handleCloseVote = () => {
        history.push("/" + lang)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const json = Object.fromEntries(new FormData(e.target).entries())

        setOpenInfo(true)

        sendMessageContact(json)
            .then(() => {
                setTextSend(data.text.send_successfully)
                if (!getShowedQualification()) {
                    setTimeout(() => {
                        setOpenQua(true)
                        setOpenInfo(false)
                    }, 1000)
                } else {
                    setTimeout(() => history.push("/" + lang), 1000)
                }
            })
            .catch(() => {
                setTextSend(data.text.send_error)
                setTimeout(() => setOpenInfo(false), 1000)
            })
    }

    return (
        <>
            <Container>
                <form onSubmit={handleSubmit}>
                    <h3>{data.text.form_title}</h3>
                    <p>{data.text.form_subtitle}</p>
                    <Input required name="name" placeholder={data.text.name_placeholder + "*"} />
                    <Input
                        required
                        name="mail"
                        type="email"
                        placeholder={data.text.mail_placeholder + "*"}
                    />
                    <Input
                        required
                        name="phone"
                        type="number"
                        placeholder={data.text.phone_placeholder + "*"}
                    />
                    <Input
                        required
                        name="company"
                        placeholder={data.text.company_placeholder + "*"}
                    />
                    <Input
                        required
                        name="country"
                        placeholder={data.text.country_placeholder + "*"}
                    />
                    <Textarea
                        required
                        name="message"
                        placeholder={data.text.message_placeholder + "*"}
                    />
                    <div id="obliTags">{data.text.obligatory_placeholder}</div>
                    <Button as="button" type="submit" id="button">
                        {data.text.send}
                    </Button>

                    <Button
                        onClick={() => {
                            history.push("/" + lang)
                        }}
                        invert
                        style={{ marginTop: "10px" }}
                        id="button"
                    >
                        Volver
                    </Button>
                </form>
                <MessageInfoContainer open={openInfo}>
                    <div id="background" />
                    <div id="message">
                        <h5>{textSend ? textSend : data.text.sending}</h5>
                    </div>
                </MessageInfoContainer>
            </Container>
            <Qualification open={openQua} onClose={handleCloseVote} />
        </>
    )
}

export default Contact
