import { MCardContainer } from "./MainCard.style"
import Button from './Button'
import { useContext } from "react"
import { DataContext } from "../../pages/Main"
import { Link } from "react-router-dom"

const MainCard = ({src, text, to, alt}) => {
    const data = useContext(DataContext)
    return (
        <MCardContainer to={to} as={Link}>
            <img src={src} alt={alt} />
            <div id="contdiv">
                <div className="mcardtitle">
                    {text?.toUpperCase()}
                </div>
                <Button invert>{data.text.enter}</Button>
            </div>
        </MCardContainer>
    )
}

export default MainCard
