import styled from "styled-components"

export const VPlayerContainer = styled.div`
    position: relative;
    height: 213px;
    cursor: pointer;

    #preview {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
    }

    #playButton {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
`
