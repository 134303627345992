import Parse from "parse"

Parse.initialize(process.env.REACT_APP_PARSE_USERNAME, process.env.REACT_APP_PARSE_PASSWORD)
Parse.serverURL = process.env.REACT_APP_PARSE_URL

const VisitorByQR = Parse.Object.extend("visitorByQr")
const VisitorByPage = Parse.Object.extend("visitorByPage")
const MessageContact = Parse.Object.extend("messagesContact")
const Qualification = Parse.Object.extend("qualification")

let ip = undefined
let showedQualification = false

export const getIP = () => ip
export const getShowedQualification = () => showedQualification
export const setShowedQualification = () => (showedQualification = true)

export const addVisitor = () =>
    new Promise((resolve) => {
        const visitor = new VisitorByQR()

        fetch(process.env.REACT_APP_IPREGISTRY_URL)
            .then((response) => response.json())
            .then(async (data) => {
                const { city, country } = data.location
                const { code, name } = country

                await visitor.save({
                    ip: data.ip,
                    location: {
                        code,
                        name,
                        city,
                    },
                })
                ip = data.ip

                const query = new Parse.Query(Qualification)
                query.equalTo("ip", ip)

                const quaElements = await query.first()
                console.log(quaElements)

                if (quaElements) showedQualification = true

                resolve()
            })
            .catch(() => {
                visitor
                    .save({ ip: undefined, location: undefined })
                    .catch((e) => console.error(e.message))

                resolve(undefined)
            })
    })

export const addPageView = (page, text) => {
    const visitor = new VisitorByPage()
    visitor.save({
        ip,
        page,
        text,
    })
}

export const sendMessageContact = (json) => {
    const message = new MessageContact()
    return message.save(json)
}

export const sendQualification = (stars) => {
    const qualification = new Qualification()

    return qualification.save({
        ip,
        stars,
    })
}
