import HomeBody from "../components/home/HomeBody"
import HomeSlider from "../components/home/HomeSlider"
import { HomeContainer } from "./Home.style"

const Home = () => {
    return (
        <HomeContainer>
            <HomeSlider />
            <HomeBody />
        </HomeContainer>
    )
}

export default Home