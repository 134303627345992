import { useContext, useEffect } from "react"
import PDFViewer from "../components/ui/PDFViewer"
import { addPageView } from "../parse/addparse"
import { DataContext } from "./Main"

const Service = () => {
    const data = useContext(DataContext)

    useEffect(() => {
        addPageView(window.location.pathname)
    }, [])

    return <PDFViewer data={data?.services} />
}

export default Service
