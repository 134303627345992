import "./App.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import { Route, Switch } from "react-router-dom"
import Home from "./pages/Home"
import Main from "./pages/Main"

function App() {
    return (
        <Switch>
            <Route exact path="/" children={<Home />} />
            <Route path="/:lang" children={<Main />} />
        </Switch>
    )
}

export default App
